import { useRef, useEffect } from "react"

export default function usePositionFooter(location) {
  const copyrightFooterRef = useRef()

  const runAfterImagesLoaded = () => {
    setTimeout(() => {
      if (copyrightFooterRef.current) {
        //TODO: replace this call to document.querySelector with a ref
        if (
          window.innerHeight >
            document.querySelector(".container-fluid").offsetHeight &&
          !(
            location.pathname === "/under-the-stairs" ||
            location.pathname === "/under-the-stairs/"
          )
        ) {
          copyrightFooterRef.current.style.position = "fixed"
          copyrightFooterRef.current.style.bottom = 0
          copyrightFooterRef.current.style.right = "15px"
        } else {
          copyrightFooterRef.current.style.position = "relative"
          copyrightFooterRef.current.style.width = "100%"
          copyrightFooterRef.current.style.textAlign = "right"
        }
      }
    }, 100)
  }

  useEffect(runAfterImagesLoaded, [location, copyrightFooterRef])

  return [copyrightFooterRef, runAfterImagesLoaded]
}
