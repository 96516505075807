import React, { useEffect, forwardRef } from "react"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import "./layout.css"

export default forwardRef(function Layout(
  { children, location },
  copyrightFooterRef
) {
  //hacky redirect to www https site
  useEffect(() => {
    if (
      window.location.href === "http://lina-pin.com" ||
      window.location.href === "http://lina-pin.com/"
    ) {
      window.location.href = "https://www.lina-pin.com"
    }
  })

  const isUnderTheStairs =
    location.pathname === "/under-the-stairs" ||
    location.pathname === "/under-the-stairs/"

  return (
    <Container
      fluid
      style={
        isUnderTheStairs
          ? {
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }
          : null
      }
    >
      <Row style={{ marginBottom: "1em" }}>
        <Col>
          <Navbar bg="light" expand="lg">
            <Link className="navbar-brand" to="/">
              Lina "Pin" Janas
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto"></Nav>
              <Nav>
                <Link
                  className={`nav-link ${
                    location.pathname === "/co-paintings" ||
                    location.pathname === "/co-paintings/"
                      ? "active"
                      : ""
                  }`}
                  to="/co-paintings/"
                >
                  CO Paintings
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/ny-paintings" ||
                    location.pathname === "/ny-paintings/"
                      ? "active"
                      : ""
                  }`}
                  to="/ny-paintings/"
                >
                  NY Paintings
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/sf-paintings" ||
                    location.pathname === "/sf-paintings/"
                      ? "active"
                      : ""
                  }`}
                  to="/sf-paintings/"
                >
                  SF Paintings
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/la-paintings" ||
                    location.pathname === "/la-paintings/"
                      ? "active"
                      : ""
                  }`}
                  to="/la-paintings/"
                >
                  LA Paintings
                </Link>
                <Link
                  className={`nav-link ${isUnderTheStairs ? "active" : ""}`}
                  to="/under-the-stairs/"
                >
                  Under The Stairs
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/contact" ||
                    location.pathname === "/contact/"
                      ? "active"
                      : ""
                  }`}
                  to="/contact/"
                >
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
      {children}
      <div ref={copyrightFooterRef}>&#169; Lina Janas 2011 - 2024</div>
    </Container>
  )
})
